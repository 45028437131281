import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { useEmployee } from '@/hooks/useEmployees';
import Loading from '@/shared/components/Loading';
import BackButton from '@/shared/components/button/BackButton';
import EmployeeMain from './components/EmployeeMain';
import EmployeeTabs from './components/EmployeeTabs';

const EmployeeProfilePage = () => {
  const { isLoading, loadEmployeeByIdRef, employee } = useEmployee();
  const paramRoute = useParams();

  useEffect(() => {
    if (paramRoute && paramRoute.id) {
      loadEmployeeByIdRef(paramRoute.idEmployee);
    }
  }, [paramRoute]);


  if (isLoading || !employee) return <Loading />;

  return (
    <Container>
      <BackButton />

      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <EmployeeMain />
            </Row>
          </Col>
          <EmployeeTabs />
        </Row>
      </div>
    </Container>
  );
};

export default EmployeeProfilePage;

