/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
import React from 'react';
import {
    Card, CardBody, Col, Progress,
} from 'reactstrap';
import DateRangeIcon from 'mdi-react/CubeSendIcon';

const _CardDash = ({
    title,
    description,
    colorStatus = '#5e72e4',
    showProgressBar = false,
    percentageProgress = 0,
}) => (
    <Col md={12} xl={3} lg={6} xs={12}>
        <Card>
            <CardBody className="dashboard__card-widget" style={{ borderBottom: `2px solid ${colorStatus}` }}>
                <div className="mobile-app-widget">
                    <div className="mobile-app-widget__top-line mobile-app-widget__top-line--pink">
                        <p className="mobile-app-widget__total-stat color_primary" style={{ color: colorStatus }}>{title}</p>
                        <DateRangeIcon className="dashboard__trend-icon" />
                    </div>
                    <div className="mobile-app-widget__title">
                        <h5 style={{ color: colorStatus }}>{description}</h5>
                    </div>

                    {showProgressBar && (
                        <div className=" mt-4 progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--label-top">
                            <Progress value={percentageProgress}><p className="progress__label">{percentageProgress}%</p></Progress>
                        </div>
                    )}
                </div>
            </CardBody>
        </Card>
    </Col>
);

export const CardDash = React.memo(_CardDash);
