/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import moment from 'moment';
import React from 'react';
import {
    Col,
} from 'reactstrap';
import CardChargeItem from './CardChargeItem';

const ListChageItem = ({
    charges = [],
}) => (
    <Col md={12} xl={6} lg={6} xs={12}>
        <h5 className="list-product-title">Histórico de Pagamentos</h5>
        <div className="list-product-items">
            {charges
                .sort((a, b) => new Date(b.due_at).getTime() - new Date(a.due_at).getTime())
                .map(item => (
                    <CardChargeItem
                        dueAt={moment(item.due_at).format('DD/MM/YYYY')}
                        paidAt={item.paid_at ? moment(item.paid_at).format('DD/MM/YYYY') : 'N/A'}
                        paymentMethod={item.payment_method.public_name}
                        price={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount)}
                        status={item.status}
                    />
                ))}
        </div>
    </Col>
);

export default React.memo(ListChageItem);
