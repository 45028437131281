import React, { useCallback } from 'react';
import CloseIcon from 'mdi-react/CloseIcon';
import * as _ from 'lodash';

const TopbarSearch = ({
  handleSearchChange,
  debounceTime = 500,
  showButton = false,
}) => {
  const handleChange = useCallback((e) => {
    if (e.keyCode === 13) {
      return;
    }
    handleSearchChange(e);
  }, [handleSearchChange]);

  return (
    <div className="topbar__search">
      <input
        placeholder="Buscar por nome..."
        className="topbar__search-field"
        onChange={_.debounce(handleChange, debounceTime)}
      />
      {showButton && (
        <button
          className="topbar__btn topbar__search-btn"
          type="button"
          aria-label="topbar search button"
        >
          <CloseIcon />
        </button>
      )}

    </div>
  );
};

export default TopbarSearch;
