/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { useCampaign } from '@/hooks/useCampaign';
import moment from 'moment';
import PlayIcon from 'mdi-react/PlayIcon';
import PauseIcon from 'mdi-react/PauseIcon';
import StopIcon from 'mdi-react/StopIcon';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const CampaignMain = () => {
  const { campaign, updateStatusCampaign } = useCampaign();

  const handleStatus = async (status) => {
    try {
      await updateStatusCampaign(campaign.IdRef, status);
    } catch (err) {
      console.log(err);
    }
  };

  const memoStatus = useMemo(() => {
    switch (campaign.Status) {
      case 1:
        return 'Em andamento';

      case 2:
        return 'Fora Periodo';

      case 3:
        return 'Cancelado';

      default:
        return 'Em andamento';
    }
  }, [campaign]);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{campaign.Titulo}</p>
              <p className="profile__work">Data agendamento:</p>
              <p className="profile__name">{moment(campaign.DataAgendamento).format('DD/MM/yyyy HH:mm')}</p>

            </div>
          </div>
          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{memoStatus}</p>
              <p className="profile__stat-title">Status</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{campaign.CampanhaContato.length}</p>
              <p className="profile__stat-title">Contatos</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{campaign.SegundosBaseParaEnvio} s</p>
              <p className="profile__stat-title">Tempo de envio</p>
            </div>
          </div>

          <div className="profile__stats">
            <div className="profile__stat pointer" onClick={() => handleStatus(1)}>
              <p className="profile__stat-number">
                <PlayIcon size={35} />
              </p>
              <p className="profile__stat-title">Iniciar</p>
            </div>
            <div className="profile__stat pointer" onClick={() => handleStatus(2)}>
              <p className="profile__stat-number">
                <PauseIcon size={35} />
              </p>
              <p className="profile__stat-title">Pausar</p>
            </div>
            <div className="profile__stat pointer" onClick={() => handleStatus(3)}>
              <p className="profile__stat-number">
                <StopIcon size={35} />

              </p>
              <p className="profile__stat-title">Parar</p>
            </div>
          </div>
        </CardBody>

      </Card>
    </Col>
  );
};
export default CampaignMain;
