import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { useHistory } from 'react-router';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import WrappedRoutes from './WrappedRoutes';
import Account from './WrappedRoutes/Account';

const Router = () => {
  const { auth } = useAuth();
  const { replace } = useHistory();
  useEffect(() => {
    if (auth.isAuthenticated) {
      replace('/');
    } else {
      replace('/account');
    }
  }, [auth]);

  return (
    <MainWrapper>
      <main>
        <Switch>
          {auth.isAuthenticated ? <Route path="/" component={WrappedRoutes} /> : <Route path="/account" component={Account} />}
          <Route path="/404" component={NotFound404} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
