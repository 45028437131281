/* eslint-disable no-underscore-dangle */
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const _CardSkeleton = () => (
  <Skeleton count={5} height={40} />
);

export const CardSkeleton = React.memo(_CardSkeleton);

