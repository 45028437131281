import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  input, label, meta: { touched, error },
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    multiline
    value={input.value}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
};

const CampaignSettings = ({ onSubmit, initialValues }) => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {({ handleSubmit, form, values }) => (
      <form className="material-form" onSubmit={handleSubmit}>
        <div>
          <span className="material-form__label">Titulo da Campanha</span>
          <Field
            name="title"
            component={renderTextField}
            placeholder="Name"
          />
        </div>
        <div>
          <span className="material-form__label">Data Inicial</span>
          <Field
            name="initialDate"
            component={renderTextField}
            placeholder="example@mail.com"
            type="text"
          />
        </div>
        <div>
          <span className="material-form__label">Data Final</span>
          <Field
            name="finallyDate"
            component={renderTextField}
            placeholder="Registro do documento"
            type="text"
          />
        </div>
        <div>
          <span className="material-form__label">Data de Agendamento</span>
          <Field
            name="scheduleDate"
            component={renderTextField}
            type="text"
          />
        </div>
        <div>
          <span className="material-form__label">Mensagem</span>
          <Field
            name="message"
            component={renderTextField}
            placeholder="Type message here"
          />
        </div>
        {/* <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">Atualizar Perfil</Button>
          <Button type="button" onClick={form.reset}>
            Cancel
          </Button>
        </ButtonToolbar> */}
      </form>
    )}
  </Form>
);

CampaignSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CampaignSettings;
