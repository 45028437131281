/* eslint-disable react/jsx-indent */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useHistory } from 'react-router';
import { Button, Col } from 'reactstrap';

const _BackButton = ({ handleClick }) => {
    const history = useHistory();

    return (
        <Col md={12} lg={12} xl={1}>
            <Button type="button" onClick={handleClick ?? history.goBack}>
                Voltar
            </Button>
        </Col>
    );
};

const BackButton = React.memo(_BackButton);

export default BackButton;
