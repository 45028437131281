/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { useCompany } from '@/hooks/useCompany';
import { useAttendance } from '@/hooks/useAttendance';
import { useHistory } from 'react-router';
import { useSubscription } from '@/hooks/useSubscription';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ProfileMain = () => {
  const { company } = useCompany();
  const { subscription } = useSubscription();
  const { lastData } = useAttendance();
  const history = useHistory();

  const handleShowMorePlan = () => {
    if (subscription) {
      history.push(`/company/${company.IdRef}/subscriptions`);
    }
  };
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{company.Nome}</p>
              <p className="profile__work">{company.DocumentoRegistro}</p>
              <p className="profile__contact">{company.Email}</p>
              <p className="profile__contact" dir="ltr">{company.Documento}</p>
            </div>
          </div>

          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{lastData.countAttendancesOpenning}</p>
              <p className="profile__stat-title">Atendimentos Abertos</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{company.employees}</p>
              <p className="profile__stat-title">Usuários</p>
            </div>

            <div className="profile__stat" onClick={handleShowMorePlan}>
              <p className="profile__stat-number">{subscription ? `${String(subscription.plan.name).slice(0, 5)}...` : 'N/A'}</p>
              <p
                style={{ cursor: 'pointer' }}
                className="profile__stat-title"
              >Ver mais
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default ProfileMain;
