/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import {
    PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Panel from '@/shared/components/Panel';

const style = {
    left: 0,
    width: 150,
    lineHeight: '24px',
    position: 'relative',
};

const renderLegend = ({ payload }) => (
    <ul className="dashboard__chart-legend">
        {payload.map(entry => (
            <li key={entry.payload.id}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
        ))}
    </ul>
);


const _CardGraph = ({
    title, listServicesOpen, listServicesClosed, date, week = false,
}) => {
    const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

    const onMouseMove = (e) => {
        if (e.tooltipPosition) {
            setCoordinates({ x: e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
        }
    };

    const data = [{
        id: 0, name: 'Abertos', value: listServicesOpen.length, fill: '#4ce1b6',
    }, {
        id: 1, name: 'Fechados', value: listServicesClosed.length, fill: '#ff7675',
    }];

    return (
        <Panel
            lg={6}
            xl={4}
            md={12}
            subhead={`${week ? `${date.day}/` : ''}${date.month < 9 ? `0${date.month}` : date.month}/${date.year}`}
            title={title}
        >
            <div className="dashboard__visitors-chart">
                <p className="dashboard__visitors-chart-title">Total atendimentos</p>
                <p className="dashboard__visitors-chart-number">{listServicesOpen.length + listServicesClosed.length}</p>
                <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={220}>
                    <PieChart className="dashboard__chart-pie-container">
                        <Tooltip position={coordinates} />
                        <Pie
                            data={data}
                            dataKey="value"
                            cy={110}
                            innerRadius={70}
                            outerRadius={100}
                            onMouseMove={onMouseMove}
                        />
                        <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style} content={renderLegend} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Panel>
    );
};



export const CardGraph = React.memo(_CardGraph);
