import React, { useEffect, useMemo } from 'react';
import {
  Col,
  Container, Row,
} from 'reactstrap';
import { useDashboard } from '@/hooks/useDashboard';
import { useChannel } from '@/hooks/useChannel';
import { CardDash } from './components/CardDash';
import { CardGraph } from './components/CardGraph';



const DashboardPage = () => {
  const { loadChannelsByFilter, allChannels } = useChannel();
  const {
    loadData,
    loadAllDataServicesOpenAndClosedByDay,
    loadAllDataServicesOpenAndClosedByMonth,
    loadAllDataServicesOpenAndClosedByWeek,
    listServicesByDay,
    listServicesByWeek,
    listServicesByMonth,
    data,
  } = useDashboard();

  const memoDateActual = useMemo(() => {
    const date = new Date();
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }, []);

  useEffect(() => {
    loadAllDataServicesOpenAndClosedByDay(memoDateActual.day, memoDateActual.month, memoDateActual.year);
    loadAllDataServicesOpenAndClosedByMonth(memoDateActual.month, memoDateActual.year);
    loadAllDataServicesOpenAndClosedByWeek(memoDateActual.day, memoDateActual.month, memoDateActual.year);
  }, []);

  useEffect(() => {
    loadData();
    // loadChannelsByFilter();
  }, []);


  return (
    <Container className="dashboard">
      <Row>
        <CardDash title={data?.servicesOpen} description="Atendimentos Abertos" />
        <CardDash title={data?.servicesClosed} description="Atendimentos Fechados" />
        <CardDash colorStatus="green" title={allChannels.data.filter(channel => !channel.desconectado).length} description="Canais conectados" />
        <CardDash colorStatus="#f1003b" title={allChannels.data.filter(channel => channel.desconectado).length} description="Canais Desconectados" />
      </Row>

      <iframe
        title="empresas_atividades - Página 1"
        width="100%"
        height="600"
        style={{ marginBottom: 30 }}
        src="https://app.powerbi.com/view?r=eyJrIjoiZjg2NzllYWMtMzllNy00ZGY1LTk1YzItZTVlYjgzNjQwZDVkIiwidCI6ImEyNGVmN2IxLTkxZWMtNGY5My05ODI0LTk1NGExNzhjYWIzZiJ9"
        frameBorder="0"
        allowFullScreen="true"
      />

      <Row>

        <CardGraph
          title="Atendimentos do Mês"
          date={memoDateActual}
          listServicesOpen={listServicesByMonth?.listServicesOpenByMonth}
          listServicesClosed={listServicesByMonth?.listServicesClosedByMonth}
        />

        <CardGraph
          title="Atendimentos da Semana"
          date={memoDateActual}
          week
          listServicesOpen={listServicesByWeek?.listServicesOpenByWeek}
          listServicesClosed={listServicesByWeek?.listServicesClosedByWeek}
        />

        <CardGraph
          title="Atendimentos do dia"
          date={memoDateActual}
          week
          listServicesOpen={listServicesByDay?.listServicesOpenByDay}
          listServicesClosed={listServicesByDay?.listServicesClosedByDay}
        />


      </Row>
    </Container>
  );
};


export default DashboardPage;
