import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router';
import { Card, CardBody, Col } from 'reactstrap';
import { CardSkeleton } from '@/shared/components/skeleton';
import { useChannel } from '@/hooks/useChannel';
import { useCompany } from '@/hooks/useCompany';

import ChannelCard from './ChannelCard';

const ListChannels = ({ channels }) => {
  const { loadChannelsByCompanyId, isLoading } = useChannel();
  const { company } = useCompany();
  const params = useParams();

  const onPageChanged = async ({ selected }) => {
    const data = selected + 1;
    await loadChannelsByCompanyId(company.Id, {
      page: data,
    });
  };

  const memoTitle = useMemo(() => {
    if (!isLoading && !channels.data.length) return 'Nenhum resultado encontrado';
    return 'Canais da empresa';
  }, [isLoading]);


  return (
    <Col md={12} lg={4} xl={12}>
      <Card className="card--not-full-height">
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{memoTitle}</h5>
          </div>

          {isLoading ? <CardSkeleton /> : (

            <>
              {channels?.data?.map(({
                IdRef,
                Titulo,
                IdContato,
                Status,
              }) => (
                <ChannelCard
                  key={IdRef}
                  name={Titulo}
                  link={`/company/${params.id}/channel/${IdRef}`}
                  contact={IdContato}
                  status={Status}
                />
              ))}

              <ReactPaginate
                className="pagination"
                previousClassName="pagination__link pagination__link--arrow"
                nextClassName="pagination__link pagination__link--arrow"
                pageClassName="pagination__item page-item"
                pageLinkClassName="pagination__link page-link"
                activeClassName="pagination__item page-item active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={onPageChanged}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(channels.totalCount / Number(channels.limit))}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ListChannels;
