/* eslint-disable no-param-reassign */
import axios from 'axios';

const apiCustom = axios.create({
    // baseURL: 'https://admin.ihelpchat.com',
    // baseURL: 'https://ihelp-adm-api.vercel.app',
    baseURL: 'https://admapi.ihelpchat.com',
    // baseURL: 'http://localhost:4000',
});

apiCustom.interceptors.request.use((config) => {
    const token = localStorage.getItem('@ihelpToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default apiCustom;
