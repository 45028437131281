import React, { useState, useMemo } from 'react';
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import showResults from '@/utils/showResults';
import { useCampaign } from '@/hooks/useCampaign';
import CampaignSettings from './CampaignSettings';
import CampaignContacts from './CampaignContacts';

const CampaignTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { campaign } = useCampaign();

  const memoInitialValues = useMemo(() => {
    if (campaign) {
      return {
        title: campaign.Titulo,
        message: campaign.Mensagem,
        initialDate: campaign.HorarioInicial,
        finallyDate: campaign.HorarioFinal,
        scheduleDate: campaign.DataAgendamento,
      };
    }
    return {
      title: '',
      message: '',
      initialDate: '',
      finallyDate: '',
      scheduleDate: '',
    };
  }, [campaign]);

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };


  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => handleToggle('1')}
                >
                  Informações
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => handleToggle('2')}
                >
                  Contatos
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <CampaignSettings
                  onSubmit={showResults}
                  initialValues={memoInitialValues}
                />
              </TabPane>

              <TabPane tabId="2">
                <CampaignContacts contacts={campaign?.CampanhaContato} />
              </TabPane>


            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};
export default CampaignTabs;
