/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useState } from 'react';
import { getSubscriptionByCustomrId, getChargesByCustomrId } from '@/shared/services/index';
import PropTypes from 'prop-types';

const SubscriptionContext = createContext({});

const SubscriptionProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [charges, setCharges] = useState([]);
    const [error, setError] = useState(false);

    const loadSubscriptionByCustomerId = async (customerId) => {
        try {
            const { payload } = await getSubscriptionByCustomrId(customerId);
            if (payload.data.subscriptions.length) {
                setSubscription(payload.data.subscriptions[0]);
            } else {
                setSubscription(null);
            }
        } catch (err) {
            setError(true);
        }
    };

    const loadChargesByCustomerId = async (customerId) => {
        try {
            const { payload } = await getChargesByCustomrId(customerId);
            if (payload.data.charges.length) {
                setCharges(payload.data.charges);
            } else {
                setCharges([]);
            }
        } catch (err) {
            setError(true);
        }
    };

    const resetContext = () => {
        setCharges([]);
        setSubscription(null);
    };

    const valuesContext = {
        isLoading,
        subscription,
        charges,
        error,
        // Functions
        loadSubscriptionByCustomerId,
        loadChargesByCustomerId,
        resetContext,
    };

    return (
        <SubscriptionContext.Provider
            value={valuesContext}
        >
            {children}
        </SubscriptionContext.Provider>
    );
};

SubscriptionProvider.propTypes = {
    children: PropTypes.node,
};

const useSubscription = () => useContext(SubscriptionContext);

export { SubscriptionProvider, useSubscription };
