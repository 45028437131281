import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  input, label, meta: { touched, error },
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
};

const ChannelSettings = ({ onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    mutators={{
      // potentially other mutators could be merged here
      ...arrayMutators,
    }}
  >
    {({ handleSubmit, form, values }) => (
      <form className="material-form" onSubmit={handleSubmit}>
        <div>
          <span className="material-form__label">Titulo do Canal</span>
          <Field
            name="title"
            component={renderTextField}
            placeholder="Name"
          />
        </div>

        <div>
          <span className="material-form__label">Descrição</span>
          <Field
            name="description"
            component={renderTextField}
            placeholder="Type message here"
            multiline
            rowsMax="4"
          />
        </div>


        <div className="employee-checkbox-group">
          <Field
            name="status"
            type="checkbox"
            component={renderCheckBoxField}
            label="Ativo"
            disabled={false}
            className="colored-click"
          />
        </div>

        <FieldArray name="configurations">
          {({ fields }) => (
            fields.value.map(configuration => (
              <div className="channel-group channe-first-group">


                {configuration.Nome === 'AtendimentoReceptivo' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}

                {configuration.Nome === 'ExibirNomeAtendenteChat' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}

                {configuration.Nome === 'DirecionarUsuarioCarteira' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}


                {configuration.Nome === 'ReaberturaAtendimento' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}

                {configuration.Nome === 'Carterizado' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}


                {configuration.Nome === 'RoboPreAtendimento' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}

                {configuration.Nome === 'RoboFluxo' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}

                {configuration.Nome === 'SomenteMensagemBoasVindas' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}


                {configuration.Nome === 'ReaberturaAtendimentoTempo' && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      multiline
                      rowsMax="4"
                    />
                  </div>
                )}


                {configuration.Nome === 'MsgFalhaBotPreatendimento' && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      placeholder="Mensagem Falha Bot Pré Atendimento"
                      multiline
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      rowsMax="4"
                    />
                  </div>
                )}


                {configuration.Nome === 'DepartamentoDefaultId' && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      placeholder="Mensagem Falha Bot Pré Atendimento"
                      multiline
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      rowsMax="4"
                    />
                  </div>
                )}

                {configuration.Nome === 'MsgErroSelecaoDepartamentos' && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      multiline
                      rowsMax="4"
                    />
                  </div>
                )}

                {configuration.Nome === 'MensagemBoasVindas' && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      multiline
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      rowsMax="4"
                    />
                  </div>
                )}


                {configuration.Nome === 'MensagemAtendimentoPassivo' && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      multiline
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      rowsMax="4"
                    />
                  </div>
                )}

                {configuration.Nome === 'EnviarMensagemEncerramento' && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}

                {values.EnviarMensagemEncerramento && (configuration.Nome === 'MensagemEncerramento') && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      multiline
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      rowsMax="4"
                    />
                  </div>
                )}


                {values.EnviarMensagemEncerramento && (configuration.Nome === 'PesquisaSatisfacao') && (
                  <div>
                    <Field
                      name={`${configuration.Nome}`}
                      type="checkbox"
                      component={renderCheckBoxField}
                      label={`${configuration.Label}`}
                      initialValue={configuration.Valor === 'true'}
                      disabled={false}
                      className="colored-click"
                    />
                  </div>
                )}


                {values.EnviarMensagemEncerramento && (configuration.Nome === 'MensagemPesquisaSatisfacao') && (
                  <div>
                    <span className="material-form__label">{`${configuration.Label}`}</span>
                    <Field
                      name={`${configuration.Nome}`}
                      component={renderTextField}
                      multiline
                      defaultValue={`${(configuration.Valor == null || configuration.Valor === undefined || configuration.Valor === 'undefined') ? '' : configuration.Valor}`}
                      rowsMax="4"
                    />
                  </div>
                )}

              </div>
            ))

          )}

        </FieldArray>




        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">Atualizar Canal</Button>
          <Button type="button" onClick={form.reset}>
            Resetar
          </Button>
        </ButtonToolbar>
      </form>
    )}
  </Form>
);

ChannelSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ChannelSettings;
