/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import moment from 'moment';
import React from 'react';
import {
    Col,
} from 'reactstrap';
import CardProductItem from './CardProductItem';

const ListProductItem = ({
    productItems = [],
}) => (
    <Col md={12} xl={6} lg={6} xs={12}>
        <h5 className="list-product-title">Lista de Produtos</h5>
        <div className="list-product-items">
            {productItems
                .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                .map(item => (
                    <CardProductItem
                        name={item.product.name}
                        createdAt={moment(item.created_at).format('DD/MM/YYYY hh:mm')}
                        price={(item.pricing_schema.short_format)}
                    />
                ))}
        </div>
    </Col>
);

export default React.memo(ListProductItem);
