import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  input, label, meta: { touched, error },
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
};

const ProfileSettings = ({ onSubmit, initialValues }) => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {({ handleSubmit, form, values }) => (
      <form className="material-form" onSubmit={handleSubmit}>
        <div>
          <span className="material-form__label">Nome Empresa</span>
          <Field
            name="username"
            component={renderTextField}
            placeholder="Name"
          />
        </div>
        <div>
          <span className="material-form__label">Email</span>
          <Field
            name="email"
            component={renderTextField}
            placeholder="example@mail.com"
            type="email"
          />
        </div>
        <div>
          <span className="material-form__label">Documento</span>
          <Field
            name="document"
            component={renderTextField}
            placeholder="Registro do documento"
            type="text"
          />
        </div>
      </form>
    )}
  </Form>
);

ProfileSettings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileSettings;
