/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Table } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { useCompany } from '@/hooks/useCompany';
import TopbarSearch from '../../../Layout/components/topbar/TopbarSearch';

const header = [
  // { id: 0, title: '' },
  { id: 7, title: 'Id' },
  { id: 1, title: 'Nome' },
  { id: 2, title: 'Email' },
  { id: 3, title: 'CNPJ' },
  // { id: 6, title: 'Status' },
];

const _ListCompanies = ({ companies }) => {
  const history = useHistory();
  const { loadCompaniesByFilter } = useCompany();

  const onPageChanged = async ({ selected }) => {
    const data = selected + 1;
    await loadCompaniesByFilter({
      page: data,
    });
  };

  const getStatusTitle = (status) => {
    if (status === 1) {
      return 'Ativo';
    }
    if (status === 1) {
      return 'Bloqueado';
    }
    if (status === 2) {
      return 'Cancelado';
    }
    if (status === 3) {
      return 'Pagamento em aberto';
    }
    return status;
  };

  const handleNavigate = (id) => {
    history.push(`/company/${id}`);
  };

  const handleSearchChange = (e) => {
    loadCompaniesByFilter({
      filter: e.target.value,
    });
  };

  return (
    <>
      <div className="mb-4">
        <TopbarSearch handleSearchChange={handleSearchChange} />
      </div>

      <Table responsive className="table--bordered">
        <thead>
          <tr>
            {header.map(item => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {companies?.data?.map(item => (
            <tr key={item.IdRef} onClick={() => handleNavigate(item.IdRef)}>
              <td>{item.Id}</td>
              <td>{item.Nome}</td>
              <td>{item.Email}</td>
              <td>{item.DocumentoRegistro}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ReactPaginate
        className="pagination"
        previousClassName="pagination__link pagination__link--arrow"
        nextClassName="pagination__link pagination__link--arrow"
        pageClassName="pagination__item page-item"
        pageLinkClassName="pagination__link page-link"
        activeClassName="pagination__item page-item active"
        breakLabel="..."
        nextLabel=">"
        onPageChange={onPageChanged}
        pageRangeDisplayed={3}
        pageCount={Math.ceil(companies.totalCount / Number(companies.limit))}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

const ListCompanies = React.memo(_ListCompanies);

export default ListCompanies;
