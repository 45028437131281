import React from 'react';
import { NavLink } from 'react-router-dom';
import showResults from '@/utils/showResults';
import ResetPasswordForm from '@/shared/components/reset_password/ResetPasswordForm';

const ResetPassword = props => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Bem vindo ao Ihelp
            <span className="account__logo"> ihelp
              <span className="account__logo-accent">ADM</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Recupere sua senha</h4>
        </div>
        <ResetPasswordForm
          {...props}
          onSubmit={showResults}
        />
        <div className="account__have-account">
          <p>Relembrou sua senha?  <NavLink to="/account/login">Login</NavLink></p>
        </div>
      </div>
    </div>
  </div>
);

export default ResetPassword;
