/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useMemo } from 'react';
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import showResults from '@/utils/showResults';
import { useEmployee } from '@/hooks/useEmployees';
import { useParams } from 'react-router';
import EmployeeSettings from './EmployeeSettings';

const EmployeeTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { employee, updateEmployee } = useEmployee();
  const params = useParams();


  const memoInitialValues = useMemo(() => {
    if (employee) {
      return {
        name: employee.Nome,
        email: employee.Email,
        phoneNumber: employee.Celular,
        perfil: employee.UsuarioPerfil,
        userDepartments: employee.userDepartments,

      };
    }
    return {
      name: '',
      email: '',
      phoneNumber: '',
      perfil: '',
      userDepartments: [],

    };
  }, [employee]);

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleUpdateEmployee = async (values) => {
    try {
      const formatedData = {
        Nome: values.name,
        Email: values.email,
        Celular: values.phoneNumber,
      };


      if (values.passwordHash) {
        formatedData.PasswordHash = values.passwordHash;
      }

      const data = {
        ...formatedData,
        userDepartments: values.userDepartments.filter((item) => {
          if (values[item.Titulo]) return item;
        }),
      };


      await updateEmployee(params.idEmployee, data);
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => handleToggle('1')}
                >
                  Informações
                </NavLink>
              </NavItem>

            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <EmployeeSettings
                  onSubmit={handleUpdateEmployee}
                  initialValues={memoInitialValues}

                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default EmployeeTabs;
