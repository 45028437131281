import React, { useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { useParams } from 'react-router';
import {
  Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { useCompany } from '@/hooks/useCompany';
import { useAttendance } from '@/hooks/useAttendance';
import { CardSkeleton } from '@/shared/components/skeleton';
import MenuDownIcon from 'mdi-react/MenuDownIcon';

import AttendanceCard from './AttendanceCard';


const ListAttendances = ({ attendances }) => {
  const params = useParams();
  const { loadAttendanceByCompanyId, isLoading } = useAttendance();
  const { company } = useCompany();
  const [isActive, setIsActive] = useState(true);

  const onPageChanged = async ({ selected }) => {
    const data = selected + 1;
    await loadAttendanceByCompanyId(company.Id, {
      page: data,
      filter: isActive,
    });
  };

  const handleFilter = async (dataFilter) => {
    setIsActive(dataFilter);
    await loadAttendanceByCompanyId(company.Id, {
      filter: dataFilter,
    });
  };

  const memoTitle = useMemo(() => {
    if (!isLoading && !attendances.data) return 'Nenhum resultado encontrado';
    return '';
  }, [isLoading, attendances.data]);

  return (
    <Col md={12} lg={4} xl={12}>
      <Card className="card--not-full-height">
        <CardBody>
          {memoTitle
            && (
              <div className="card__title">
                <h5 className="bold-text">{memoTitle}</h5>
              </div>
            )}

          <UncontrolledDropdown>
            <DropdownToggle
              className="icon icon--right"
              outline
              size="sm"
            >
              <p>{isActive ? 'Ativos' : 'Desativos'} <MenuDownIcon /></p>
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              <DropdownItem onClick={() => handleFilter(true)}>Ativos</DropdownItem>
              <DropdownItem onClick={() => handleFilter(false)}>Nao Ativos</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          {isLoading ? <CardSkeleton /> : (
            <>
              {attendances.data.map(({
                IdRef,
                ChatImagemPerfil,
                ChatIdExterno,
                Ativo,
              }) => (
                <AttendanceCard
                  key={IdRef}
                  name={ChatIdExterno}
                  img={ChatImagemPerfil}
                  link={`/company/${params.id}/attendance/${IdRef}`}
                  contact={ChatIdExterno}
                  status={Ativo ? 'Ativo' : 'Desativo'}
                />
              ))}
              <ReactPaginate
                className="pagination"
                previousClassName="pagination__link pagination__link--arrow"
                nextClassName="pagination__link pagination__link--arrow"
                pageClassName="pagination__item page-item"
                pageLinkClassName="pagination__link page-link"
                activeClassName="pagination__item page-item active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={onPageChanged}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(attendances.totalCount / Number(attendances.limit))}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ListAttendances;
