/* eslint-disable import/prefer-default-export */
import apiCustom from '../../../utils/api';

export const getChargesByCustomrId = async (customerId) => {
    try {
        const { data } = await apiCustom.get(`/charges/${customerId}`);
        return data;
    } catch (err) {
        throw new Error(err);
    }
};


